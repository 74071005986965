@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.container {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 0.5rem;
}

.header {
  @include media-breakpoint-up(md) {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
}

.title {
  margin-bottom: 0;
}

.cart {
  @include media-breakpoint-up(md) {
    position: absolute;
    right: 0;
    top: 86px;
    width: auto;
  }
}

.is-sidebar-open {
  .header {
    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .cart {
    @include media-breakpoint-up(md) {
      top: 0;
    }
  }
}
