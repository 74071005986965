@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.container {
  color: $canopy-color-primitives-gray-39;
  display: flex;
  justify-content: space-between;
  margin-bottom: $canopy-space-4x;
}

.header {
  line-height: 1.875rem;
  margin-bottom: 0;
}
