@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/variables.scss";
@import "node_modules/bootstrap/scss/mixins.scss";

.header {
  margin-bottom: 1rem;
}

.header-top-row {
  display: flex;
  justify-content: space-between;
}

.title {
  color: $canopy-color-text-primary;
  font-weight: 400;
  margin-bottom: 8px;
}

.supplier-count {
  color: $canopy-color-primitives-gray-48;
}

.subtitle {
  color: $canopy-color-primitives-gray-39;
  font-weight: 400;
}

.arrow-icon {
  margin-bottom: -3px;
}

.manage-suppliers-label {
  display: inline;
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.arrow-with-word {
  display: inline-block;
  text-decoration: inherit;
}

.catalog-word {
  padding-bottom: 2px;
}

.manage-suppliers-container {
  margin-left: 32px;
}
