@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.accordionRoot {
  margin-top: $canopy-space-8x;
  border: 1px solid $canopy-color-border-default !important;
  border-radius: $canopy-space-2x !important;
  box-shadow: 0 2px 4px $canopy-color-border-default !important;

  &::before {
    content: none !important;
  }
}

.authCardsContainer {
  display: flex;
  justify-content: space-between;
  column-gap: $canopy-space-16x;
  margin-top: $canopy-space-8x;
}

.authorizationDetailsContainer {
  min-width: 66%;
}

@media (max-width: 768px) {
  .authCardsContainer {
    flex-direction: column;
  }
}
