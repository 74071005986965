@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens.scss";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens.scss";

// 4 rem from the various min-heights and spacing + 1px border
$supplier-intake-navbar-height-with-border: calc(4rem + 1px);
// 3 rem button height + 2* 1rem padding-y + 1px border
$action-footer-height-with-border: calc(5rem + 1px);
// 208px calculated offset from:
// - vertical padding around document browser frame
// - vertical padding inside document browser
// - document browser actions toolbar height
$other-elements-besides-document-height: 208px;

.actionsFooter {
  background-color: $canopy-color-background-container;
  border-block-start: 1px solid $canopy-color-border-default;
  bottom: 0;
  inline-size: 100%;
  padding-block: $canopy-space-8x;
  padding-inline: $canopy-space-24x;
  position: fixed;
  z-index: 100;
}

.column-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100vh - $supplier-intake-navbar-height-with-border) !important;
  overflow: hidden;
}
.document-column {
  background-color: $canopy-color-background-primary;
  overflow: hidden;
  padding: $canopy-space-16x;
}
.document-browser-frame {
  background-color: $canopy-color-background-secondary;
  padding: $canopy-space-8x;
}
.document-browser-injected-style {
  // needed to override default max-height based on a different location for document browser
  // needed to override default min-height to have scrolling within the document browser
  max-height: calc(100vh - $other-elements-besides-document-height) !important;
  min-height: auto;
}
.data-entry-column {
  background-color: $canopy-color-background-secondary;
  height: calc(100vh - $supplier-intake-navbar-height-with-border - $action-footer-height-with-border) !important;
  overflow-y: scroll;
}
.action-column-padding {
  padding: $canopy-space-24x;
}
.json-code {
  white-space: pre-wrap;
}
.json-code-wrapper {
  background-color: $canopy-color-background-primary;
  padding: $canopy-space-8x;
}
