@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens" as *;

.profileWorkflowStepContainer {
  background-color: $canopy-color-background-container;
  max-width: 800px;
  height: 90vh;
  margin: 0 auto;
  text-align: center;
  border-radius: 16px;
  padding-top: 100px;
}

.formContainer {
  padding: 0 100px;
}

.formField,
.inlineFields {
  margin-bottom: $canopy-space-6x;
}

.header {
  @include canopy-typography-heading-2xlarge;
  font-weight: $canopy-typography-font-weight-bold;
  margin-bottom: $canopy-space-4x;
}

.inlineFields {
  display: flex;
  gap: $canopy-space-4x;
}

.buttonContainer {
  display: flex;
  gap: $canopy-space-4x;
  justify-content: center;
  margin-top: $canopy-space-16x;
}
