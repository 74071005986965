@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;

.title {
  color: $canopy-color-text-primary;
  font-weight: 400;
  margin-bottom: 24px;
}

.supplier-count {
  color: $canopy-color-primitives-gray-48;
}
