@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.lineItemDetail {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.lineItemDetailTitle {
  width: 160px;
}

.requiredField {
  color: $canopy-color-text-danger;
  font-style: italic;
}
