@import "node_modules/@parachutehealth/canopy-tokens-color/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-space/build/tokens";
@import "node_modules/@parachutehealth/canopy-tokens-typography/build/tokens";
@import "node_modules/bootstrap/scss/functions.scss";
@import "node_modules/bootstrap/scss/mixins.scss";
@import "node_modules/bootstrap/scss/variables.scss";

.outer-container {
  margin-block-end: 0.75rem;
  margin-block-start: 2rem;
}

.container {
  background-color: $canopy-color-background-container;
  border: 1px solid #d1d8dc;
  border-radius: 0.25rem;
  box-shadow: 0 2px 4px 0 rgba(34, 48, 54, 0.14);
  display: inline-flex;
  flex-direction: column;
  height: 120px;
  justify-content: space-between;
  margin: 0 1.25rem 1.25rem 0;
  padding: 1rem;
  text-align: left;
  width: 16rem;

  &:link {
    text-decoration: none;
  }

  &:hover {
    background-color: $canopy-color-interactive-background-secondary-hover;
    text-decoration: none;
  }

  &:active {
    background-color: $canopy-color-interactive-background-secondary-active;
    text-decoration: none;
  }
}

.inner-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.selected {
  background-color: $canopy-color-interactive-background-secondary-active;
  box-shadow: 0 0 6px 0 $canopy-color-primitives-purple-77;

  &:hover {
    background-color: $canopy-color-interactive-background-secondary-active;
  }
}

.title {
  @include canopy-typography-heading-medium;
  color: $canopy-color-text-primary;
  font-weight: $canopy-typography-font-weight-bold;
}

.subtitle {
  @include canopy-typography-body-small;
  color: $canopy-color-text-secondary;
}

.count {
  @include canopy-typography-heading-2xlarge;
  color: $canopy-color-text-danger;

  &.zero-count {
    color: $canopy-color-text-primary;
  }
}

.spinner {
  text-align: right;
}
