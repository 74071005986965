@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;
@import "app/javascript/stylesheets/variables/z-indexes.scss";
$nav-mobile-height: 60px;
$nav-mobile-line-height: 60px;

.overlay {
  background: rgba($canopy-color-primitives-gray-16, 0.5);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: ease-in 0.1s;
  z-index: $z-index-bottom-navigation-overlay;
}

.space {
  height: 100%;
}

.mobile {
  animation: fadeIn 0.2s ease-in-out;
  bottom: 0;
  max-height: 100%;
  overflow: auto;
  position: fixed;
  width: 100%;
  z-index: $z-index-bottom-navigation-mobile;
}

.body {
  animation: moveUp 0.3s ease-in;
}

.actions {
  padding: 0 15px;
  width: 100%;
}

.item {
  margin: 0 0 15px;
}

.bar {
  background-color: $canopy-color-background-secondary;
  border-top: 1px solid $canopy-color-primitives-gray-86;
  clear: both;
  height: $nav-mobile-height;
  overflow: hidden;
  width: 100%;
}

.header {
  cursor: pointer;
  float: left;
  font-weight: bold;
  padding: 15px;
}

.title {
  float: left;
}

.step {
  font-size: 0.833em;
  line-height: 0.8;
  margin-bottom: 0;
  text-transform: uppercase;
}

.button {
  float: right;
  padding: 0;
  width: 120px;
}

.button a {
  border-radius: 0;
  height: $nav-mobile-height - 1px;
  line-height: $nav-mobile-height - 1px;
}

.icon {
  float: right;
  line-height: $nav-mobile-line-height;
  padding: 0 15px;
}

.buttonContainer {
  background-color: $canopy-color-primitives-white-100;
  border-bottom: 1px solid $canopy-color-primitives-gray-86;
  display: flex;
}

.actionButtonContainer {
  padding: $canopy-space-8x $canopy-space-4x $canopy-space-8x $canopy-space-8x;
  width: 100%;
}

.followersButtonContainer {
  padding: $canopy-space-8x $canopy-space-8x $canopy-space-8x $canopy-space-4x;
  width: 100%;
}
