@use "node_modules/@parachutehealth/canopy-tokens-color/build/tokens" as *;
@use "node_modules/@parachutehealth/canopy-tokens-space/build/tokens" as *;

.notice {
  border-radius: 8px;
  color: $canopy-color-text-primary;
  padding: $canopy-space-2x;
  border: 1px solid transparent;
  margin-bottom: $canopy-space-4x;
  display: flex;
  justify-content: space-between;
}

.noticeIcon {
  margin: $canopy-space-4x;
  display: flex;
}

.noticeLink {
  font-weight: bolder;
  margin: 0 $canopy-space-4x;
}

.info {
  background-color: $canopy-color-background-information;
  border-color: $canopy-color-primitives-blue-60;

  .noticeIcon svg {
    color: $canopy-color-primitives-blue-41;
  }
}

.danger {
  background-color: $canopy-color-background-danger;
  border-color: $canopy-color-primitives-red-71;

  .noticeIcon svg {
    color: $canopy-color-primitives-red-48;
  }
}

.warning {
  background-color: $canopy-color-background-warning;
  border-color: $canopy-color-primitives-yellow-62;

  .noticeIcon svg {
    color: $canopy-color-primitives-yellow-48;
  }
}

.success {
  background-color: $canopy-color-primitives-teal-97;
  border-color: $canopy-color-brand-secondary;

  .noticeIcon svg {
    color: $canopy-color-brand-secondary;
  }
}

.primary {
  background-color: $canopy-color-background-primary;
  border-color: $canopy-color-primitives-gray-48;

  .noticeIcon svg {
    color: $canopy-color-text-primary;
  }
}

.secondary {
  background-color: $canopy-color-background-secondary;
  border-color: $canopy-color-primitives-gray-74;

  .noticeIcon svg {
    color: $canopy-color-primitives-gray-48;
  }
}

/* Additional variants */
.shadow {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
}

.noticeActions {
  display: flex;
  align-items: center;
  align-self: start;

  .divider {
    width: 1px;
    border-left: 1px solid $canopy-color-primitives-gray-60;
    margin: $canopy-space-4x;
  }
}

/* Make the notice body take up the bulk of the space and push the close button to the end, if it exists */
.noticeBody {
  flex: 1;
  margin: $canopy-space-4x;
}

/* Mostly overriding Canopy Tertiary button variant behavior to make it look more like the Canopy Modal close button */
.closeButton {
  background-color: transparent !important;

  svg {
    height: 1.2rem;
    width: 1.2rem;
    color: $canopy-color-primitives-gray-27;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
    svg {
      color: rgba(0, 0, 0);
    }
  }
}
